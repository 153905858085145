import './header-top.css'
export function HeaderTop() {
    return (
        <div className="d-flex justify-content-between iconss p-2">
            <div className='ms-3 icn'>
                <span className="bi bi-telephone"> +91 9179644717</span>
            </div>
            <div>
                <div className="d-flex ic gap-2">
                    <a href="https://www.linkedin.com/in/rajendra-kumar-kushwaha-8584a8271/" target="_blank"><span className="bi bi-linkedin text-white me-4" /></a>
                    <a href="https://github.com/RajendraKumarKushwaha/" target="_blank"><span className="bi bi-github text-white me-4" /></a>
                    <a href="https://www.rajendrakushwaha.com/" target="_blank"><span className="bi bi-briefcase text-white me-4" /></a>
                    <a href="https://www.instagram.com/rajendrakushwaha1732/" target="_blank"><span className="bi bi-instagram text-white me-3" /></a>
                    
                   
                </div>
            </div>
        </div>
    )
}
import React, { useState } from 'react';
import FadeLoader from 'react-spinners/FadeLoader'; // Import the FadeLoader
import { ProcessedImage } from './processed-image';
import { removeBackground } from '@imgly/background-removal';
import './upload-image.css';

const IMAGEKIT_PRIVATE_API_KEY = 'private_5xrcIVp1q8bZIY2l+u0ROYapzU0='; // Replace with your private API key

export function UploadImage() {
    const [processedImage, setprocessedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);

    // Simulates real-time progress by incrementing at intervals
    const simulateProgress = (start, end, duration) => {
        return new Promise((resolve) => {
            const interval = 100; // Update progress every 100ms
            const step = ((end - start) / duration) * interval;

            let currentProgress = start;
            const intervalId = setInterval(() => {
                currentProgress += step;
                if (currentProgress >= end) {
                    currentProgress = end;
                    clearInterval(intervalId);
                    resolve();
                }
                setProgress(Math.floor(currentProgress));
            }, interval);
        });
    };

    const uploadToImageKit = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        formData.append('folder', '/uploads');

        await simulateProgress(0, 50, 2000); // Simulate progress for upload (2 seconds)

        const response = await fetch('https://upload.imagekit.io/api/v1/files/upload', {
            method: 'POST',
            headers: {
                Authorization: `Basic ${btoa(`${IMAGEKIT_PRIVATE_API_KEY}:`)}`,
            },
            body: formData,
        });

        if (response.ok) {
            const data = await response.json();
            return `${data.url}?tr=q-80,fo-auto`;
        } else {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to upload image to ImageKit');
        }
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) {
                setError('File size is too large. Please upload a smaller image.');
                return;
            }

            setError(null);
            setLoading(true);
            setProgress(0);

            try {
                // Step 1: Upload and optimize the image
                const optimizedUrl = await uploadToImageKit(file);

                // Step 2: Simulate progress for background removal
                await simulateProgress(50, 100, 2000); // Simulate progress for background removal (2 seconds)

                // Step 3: Remove the background
                const result = await removeBackground(optimizedUrl);

                if (result instanceof Blob) {
                    const url = URL.createObjectURL(result);
                    setprocessedImage(url);
                } else {
                    setError('Failed to process the image. Please try again.');
                }
            } catch (err) {
                console.error('Error:', err);
                setError(err.message || 'An error occurred while processing the image.');
            } finally {
                setLoading(false);
                setProgress(100);
            }
        }
    };

    return (
        <div className="column">
            <div className="upload-button file-upload">
                <label
                    htmlFor="file-upload"
                    className="btn-upload fw-bold"
                    style={{ backgroundColor: "#800080", color: "white" }}
                >
                    <span className="bi bi-upload"></span> Upload Image
                </label>
                <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={{ display: "none" }}
                />
            </div>
            {loading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                        marginTop: '20px',
                    }}
                >
                    <FadeLoader color="#025dcc" loading={loading} />
                </div>
            )}
            {error && <p style={{ color: "red" }}>{error}</p>}
            {processedImage && (
                <div className="processed-image mt-4 ">
                    <h3>Background Removed Image:</h3>
                    <ProcessedImage processedImage={processedImage} />
                </div>
            )}
        </div>
    );
}

import { useState } from "react";
import { Accordion } from "../accordian/accordian";
import { BgSlider } from "../bg-slider/bg-slider";
import { Feature } from "../feature/feature";
import { FeatureCollage } from "../feature/feature-collage";
import { Footer } from "../footer/footer";
import { Hero } from "../hero/hero";
import { UploadFullscreen } from "../upload-fullscreen/upload-fullscreen";
import './header.css';
import { useClerk, UserButton, useUser } from "@clerk/clerk-react";
import {Link} from "react-router-dom";

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { openSignIn } = useClerk();
  const { isSignedIn, user } = useUser();

  const handleToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className="header min-vh-100">
      <header className="top-header d-flex justify-content-between align-items-center p-3">
                {/* Logo Section */}
                <div>
                    <h4 className="text-white mt-1 fw-bold">Alpha Erase</h4>
                </div>

                {/* Toggle Button for Mobile */}
                <button
                    className="menu-toggle d-md-none"
                    onClick={handleToggle}
                    style={{
                        background: "transparent",
                        border: "none",
                        color: "white",
                        fontSize: "24px",
                        cursor: "pointer",
                    }}
                >
                    ☰
                </button>

                {/* Navigation and Mobile CTA */}
                <div
                    className={`menu-container d-md-flex ${isMenuOpen ? "menu-open" : "d-none"
                        }`}
                >
                    <nav className="menu text-white fw-bold">
                        <span className="me-3 nav-font"><Link to="/" className="text-white text-decoration-none">Home</Link></span>
                        <span className="me-3 nav-font"><Link to="/about-us" className="text-white text-decoration-none">About</Link></span>
                        <span className="me-3 nav-font"><Link to="/contact-us" className="text-white text-decoration-none">Contact</Link></span>
                    </nav>

                    {/* Mobile & Desktop CTA Button */}
                    <div className="get-started w-100 mt-3 mt-md-0">
                        {
                            isSignedIn
                                ? <div>
                                    <UserButton />
                                </div>
                                : <div>
                                    <button onClick={() => openSignIn({})} className="btn btn-outline-light w-100 fw-bold d-md-none">
                                        Sigh in
                                    </button>
                                    <button onClick={() => openSignIn({})} className="btn btn-outline-light d-none fw-bold d-md-block">
                                        Sign in
                                    </button>
                                </div>
                        }


                    </div>
                </div>
            </header>
      <hr className="m-0 opacity-25" />
      {/* <BgSlider/> */}
      < UploadFullscreen />
      <div>
        < BgSlider />
      </div>
      <div>
        <Hero />
      </div>
      <div>
        <Feature />
      </div>
      <div>
        < FeatureCollage />
      </div>
      <div>
        <Accordion />
      </div>

    
    </div>
  )
}
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AboutUs } from './components/about/about';
import { ContactUs } from './components/contact/contact';
import { Footer } from './components/footer/footer';
import { Header } from './components/header/header';
import { HeaderTop } from './components/header-top/header-top';


export function App() {
  return (
    <div>
     
        <HeaderTop />
     
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Header />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/contact-us' element={<ContactUs />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  )
}


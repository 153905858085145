import { UploadImage } from "../upload-image/upload-image";

export function UploadFullscreen() {
    return (
        <div className="mt-4">
            <div className=" text-dark text-center upload">
                <h2 className="heading-2">
                    Remove <span
                        style={{
                            background: "linear-gradient(to right, #025dcc, darkblue)",
                            WebkitBackgroundClip: "text",
                            color: "transparent"
                        }}
                    >
                         Backgrounds
                    </span>  with Ease <br />
                    
                </h2>
                <p className="para fs-5">No Fees, No Limits, Just Results!</p>

                <div>
                    <UploadImage />
                </div>
            </div>
        </div>
    )
}
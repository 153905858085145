import { useState } from "react";
import { Header } from "../header/header";
import { useClerk, UserButton, useUser } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import './about.css';
import { Footer } from "../footer/footer";

export function AboutUs() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { openSignIn } = useClerk();
    const { isSignedIn, user } = useUser();

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    return (
        <div> 
             <header className="top-header d-flex justify-content-between align-items-center p-3">
                {/* Logo Section */}
                <div>
                    <h4 className="text-white mt-1 fw-bold">Alpha Erase</h4>
                </div>

                {/* Toggle Button for Mobile */}
                <button
                    className="menu-toggle d-md-none"
                    onClick={handleToggle}
                    style={{
                        background: "transparent",
                        border: "none",
                        color: "white",
                        fontSize: "24px",
                        cursor: "pointer",
                    }}
                >
                    ☰
                </button>

                {/* Navigation and Mobile CTA */}
                <div
                    className={`menu-container d-md-flex ${isMenuOpen ? "menu-open" : "d-none"
                        }`}
                >
                    <nav className="menu text-white fw-bold">
                        <span className="me-3 nav-font"><Link to="/" className="text-white text-decoration-none">Home</Link></span>
                        <span className="me-3 nav-font"><Link to="/about-us" className="text-white text-decoration-none">About</Link></span>
                        <span className="me-3 nav-font"><Link to="/contact-us" className="text-white text-decoration-none">Contact</Link></span>
                    </nav>

                    {/* Mobile & Desktop CTA Button */}
                    <div className="get-started w-100 mt-3 mt-md-0">
                        {
                            isSignedIn
                                ? <div>
                                    <UserButton />
                                </div>
                                : <div>
                                    <button onClick={() => openSignIn({})} className="btn btn-outline-light w-100 fw-bold d-md-none">
                                        Sigh in
                                    </button>
                                    <button onClick={() => openSignIn({})} className="btn btn-outline-light d-none fw-bold d-md-block">
                                        Sign in
                                    </button>
                                </div>
                        }


                    </div>
                </div>
            </header>
            <hr className="m-0 p-0" />
            <div className="">
                <div className=" text-center fw-bolder">
                    <h2 className="heading-2 about-heading text-white p-5">About Us
                        <p className="para text-capitalize" style={{ color: " #454545", fontWeight: "normal" }}>"An independent and self motivated hardworking individual dedicated towards goal."</p>
                    </h2>
                </div>
                {/* Top-section */}
                <div className="d-flex gap-3 mt-4 mb-4 container-fluid top-container">
                    <div className="left-about">
                        <div className="text-center">
                            <h3 className="heading-3">About Me</h3>
                        </div>
                        <p className="para">I am a self-taught MERN Stack Developer with 1.5 years of experience specializing in developing and deploying responsive, user-friendly web applications. I have hands-on experience building full-stack projects from scratch.</p>
                        <div className="skill-container d-flex flex-wrap justify-content-center gap-5">
                            <div className="skill-container-img text-center">
                                <img src="images/html.png" className="skill-img" alt="" />
                                <p className="para">HTML 5</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/css.webp" className="skill-img" alt="" />
                                <p className="para">CSS 3</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/js.webp" className="skill-img" alt="" />
                                <p className="para">JavaScript</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/bootstrap.png" className="skill-img" alt="" />
                                <p className="para">Bootstrap</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/react.webp" className="skill-img" alt="" />
                                <p className="para">React JS</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/express.png" className="skill-img" alt="" />
                                <p className="para">Express JS</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/node.webp" className="skill-img" alt="" />
                                <p className="para">Node JS</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/mongodb.png" className="skill-img" alt="" style={{ width: "30px" }} />
                                <p className="para">Mongo DB</p>
                            </div>


                        </div>


                    </div>
                    <div className="right-about text-end">
                        <img src="images/about.png" alt="" className="image-fuild about-img" />

                    </div>
                </div>

                {/* Bottom Section */}
                <div className="d-flex gap-3 container-fluid top-container">
                    <div className="left-about">

                        <p className="para fw-bolder text-center">Application Deployment</p>
                        <div className="skill-container d-flex flex-wrap justify-content-center gap-5">
                            <div className="skill-container-img text-center">
                                <img src="images/vercel.png" className="skill-img" alt="" />
                                <p className="para">Vercel</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/EC2" className="skill-img" alt="" />
                                <p className="para">EC2 Instance</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/s3.png" className="skill-img" alt="" />
                                <p className="para">S3 Bucket</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/firebase.webp" className="skill-img" alt="" />
                                <p className="para">Firebase </p>
                            </div>



                        </div>


                    </div>
                    <div className="right-about text-end">
                        {/* <img src="images/about.png" alt="" className="image-fuild about-img" /> */}
                        <p className="para fw-bolder text-center">Tools And Software</p>
                        <div className="skill-container d-flex flex-wrap justify-content-center gap-5">
                            <div className="skill-container-img text-center">
                                <img src="images/postman.png" className="skill-img" alt="" />
                                <p className="para">Postman</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/mongo-compas.png" className="skill-img" alt="" />
                                <p className="para">MongoDB Compass</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/vs-code.png" className="skill-img" alt="" />
                                <p className="para">VS Code</p>
                            </div>
                            <div className="skill-container-img text-center">
                                <img src="images/git.png" className="skill-img" alt="" />
                                <p className="para">GitHub</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
           
        </div>
    )
}
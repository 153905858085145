import './footer.css';
export function Footer(){
    return(
        <div className="mt-5">
            <hr/>
            <footer>
                <div className=" footer text-center p-2 ">
                    <p><span>© 2024</span> All rights reserved | Developed with dedication by <a className="text-decoration-none" style={{color:"white"}} href="https://www.rajendrakushwaha.com/" target="_blank">Rajendra</a>.</p>
                </div>
            </footer>
        </div>
    )
}
import React, { useState } from "react";
import './accordian.css'
export function Accordion() {
  const [activeIndex, setActiveIndex] = useState(null);

  const items = [
    {
      title: "Can I remove photo backgrounds using a mobile device?",
      content: "Yes, you can remove backgrounds directly from your phone using BGFreeImage in your Browser. Enjoy the convenience of removing background.",
    },
    {
      title: "What image formats does support?",
      content: "This supports popular image formats like JPEG and PNG. Ensure that the image you upload is in one of these supported formats for accurate processing.",
    },
    {
      title: "How do I make an image background transparent with?",
      content: "Simply upload your image, and the BGFreeImage will process it to remove the background, leaving a transparent background in PNG format. BGFreeImage will automatically detect the subject in your image and seamlessly separate it from the background, leaving you with a transparent result.Once the background is removed, simply click the 'Download' button to save your transparent image as a PNG file.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
   <div className="accordian mt-5">
    <h3 className="heading-3 text-center">Frequently Asked Questions</h3>
    <p className="para text-center">Below are answers to some of the most frequently asked questions.</p>
     <div className="accordion ">
       
       {items.map((item, index) => (
         <div key={index} className="accordion-item">
           <div
             className="accordion-header"
             onClick={() => toggleAccordion(index)}
           >
             <h4 className="heading-4">{item.title}</h4>
             <span className="fs-4">{activeIndex === index ? "-" : "+"}</span>
           </div>
           {activeIndex === index && (
             <div className="accordion-content">
               <p className="para">{item.content}</p>
             </div>
           )}
         </div>
       ))}
     </div>
   </div>
  );
};



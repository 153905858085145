import { useState } from "react";
import './hero.css';
export function Hero() {
    const [sliderPosition, setSliderPosition] = useState(50);

    const handleSliderChange = (e) => {
        setSliderPosition(e.target.value);

    }
    return (
        <div className=" hero-section mt-5">
            <div className="d-flex flex-column align-items-center justify-content-center m-lg-3 ">
                <div>
                    <h3 className="text-center heading-3">Remove Backgrounds with Precision and Quality — 100% Free!</h3>
                    <p className="text-center para">all for free! Our tool ensures flawless results, giving your images a professional look with no cost and no hassle.</p>
                </div>
                <div className="hero-image-parent position-relative  justify-content-center overflow-hidden ">
                    {/* background-image */}
                    <img className="my-img position-absolute top-0  w-100" src="https://ik.imagekit.io/fnqqpf1l1/alpha/hero1-w-back.jpg?updatedAt=1733202178963" style={{ clipPath: `inset(0 ${100.2 - sliderPosition}% 0 0)` }} alt="" />
                    {/* foreground-image */}
                    <img className=" foreground-img bg-white top-0  w-100" src="https://ik.imagekit.io/fnqqpf1l1/alpha/hero1-wo-back.png?updatedAt=1733202182272" style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }} alt="" />

                    <input className=" slider input-range position-absolute" type="range" min={0} max={100} value={sliderPosition} onChange={handleSliderChange} />
                </div>


            </div>
        </div>
    )
}

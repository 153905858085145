import { useClerk, UserButton, useUser } from "@clerk/clerk-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';
import './contact.css';

export function ContactUs() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { openSignIn } = useClerk();
    const { isSignedIn, user } = useUser();

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const [result, setResult] = React.useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "d7811bbb-ff23-4568-82ab-027d417236b4");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });
        const data = await response.json();

        if (data.success) {
            Swal.fire({
                title: "Successfully!",
                text: "Message sent successfully!",
                icon: "success"
              });
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };
    return (
        <div>
            <header className="top-header d-flex justify-content-between align-items-center p-3">
                {/* Logo Section */}
                <div>
                    <h4 className="text-white mt-1 fw-bold">Alpha Erase</h4>
                </div>

                {/* Toggle Button for Mobile */}
                <button
                    className="menu-toggle d-md-none"
                    onClick={handleToggle}
                    style={{
                        background: "transparent",
                        border: "none",
                        color: "white",
                        fontSize: "24px",
                        cursor: "pointer",
                    }}
                >
                    ☰
                </button>

                {/* Navigation and Mobile CTA */}
                <div
                    className={`menu-container d-md-flex ${isMenuOpen ? "menu-open" : "d-none"
                        }`}
                >
                    <nav className="menu text-white fw-bold">
                        <span className="me-3 nav-font"><Link to="/" className="text-white text-decoration-none">Home</Link></span>
                        <span className="me-3 nav-font"><Link to="/about-us" className="text-white text-decoration-none">About</Link></span>
                        <span className="me-3 nav-font"><Link to="/contact-us" className="text-white text-decoration-none">Contact</Link></span>
                    </nav>

                    {/* Mobile & Desktop CTA Button */}
                    <div className="get-started w-100 mt-3 mt-md-0">
                        {
                            isSignedIn
                                ? <div>
                                    <UserButton />
                                </div>
                                : <div>
                                    <button onClick={() => openSignIn({})} className="btn btn-outline-light w-100 fw-bold d-md-none">
                                        Sigh in
                                    </button>
                                    <button onClick={() => openSignIn({})} className="btn btn-outline-light d-none fw-bold d-md-block">
                                        Sign in
                                    </button>
                                </div>
                        }


                    </div>
                </div>
            </header>
            <hr className="m-0 p-0" />
            <div className="">
                <div className=" text-center fw-bolder">
                    <h2 className="heading-2 about-heading text-white p-5">Contact Us
                        <p className="para text-capitalize" style={{ color: " #454545", fontWeight: "normal" }}>"Great ideas start with a conversation. Reach out, and let’s chat!"</p>
                    </h2>
                </div>
            </div>

            {/* <div className="container-fluid d-flex flex-column gap-2 flex-md-row mt-4">
                <div className="left-contact  ">
                    <h3 className="heading-3">Contact Us</h3>
                    <div className="">
                        <div className="mb-3">
                            <span className="bi bi-telephone "> <a href="tel:9179644717" className="text-decoration-none para">+91-9179644717</a></span><br />
                            <span className="bi bi-envelope"> <a href="mailto:rajendra.k619@gmail.com" className="text-decoration-none para">rajendra.k619@gmail.com</a></span>
                        </div>
                       
                    </div>
                </div>
                <div className="right-contact">
                    <img src="images/contact.png" alt="" className="img-fuild right-contact-img " />
                </div>
            </div>  */}

            <div className="container-fluid flex-column flex-md-row contact-container">
                <div className="contact-form ">
                    <form onSubmit={onSubmit} className="border border-1 p-3">
                        <h3 className="heading-3 text-center">Get in Touch</h3>
                        <dl>
                            <dt className="form-label para">Name</dt>
                            <dd><input type="text" name="name" placeholder="Your Name" required className="form-control" /></dd>
                            <dt className="form-label para" >Email</dt>
                            <dd><input type="email" name="name" placeholder="Your Email" required className="form-control" /></dd>
                            <dt className="form-label para">Message</dt>
                            <dd><textarea id="message" name="message" rows="4" placeholder="Your Message" required className="form-control"></textarea></dd>
                        </dl>
                        <button className="btn btn-primary button">Send</button>
                    </form>
                   

                </div>
                <div className="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3629.7249570318586!2d81.28159377412868!3d24.529597858419315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39845b85485218df%3A0x215554915de263fb!2sPanchmatha%20Mandir%20Ghat!5e0!3m2!1sen!2sin!4v1733999723411!5m2!1sen!2sin" style={{ border: 0, width: "100%", height: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    )
}
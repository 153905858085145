import { useEffect, useState } from "react"
import './bg-slider.css'
import { UploadImage } from "../upload-image/upload-image";
import { ProcessedImage } from "../upload-image/processed-image";
export function BgSlider() {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [direction, setDirection] = useState(1);

  const handleSliderChange = (e) => {
    setSliderPosition(e.target.value);

  }
  useEffect(() => {
    const interval = setInterval(() => {
      setSliderPosition((prev) => {
        if (prev >= 100) {
          setDirection(-1); // Switch to backward direction
          return prev - 1;
        } else if (prev <= 0) {
          setDirection(1); // Switch to forward direction
          return prev + 1;
        }
        return prev + direction; // Continue moving in the current direction
      });
    }, 50); // Update every 50ms for smooth transition (adjust as needed)

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [direction]);

  return (
    <div className="mt-4">
      <div className="d-flex my-container justify-content-center m-lg-3  ">
        <div className="image-parent-bg position-relative  justify-content-center overflow-hidden ">
          {/* background-image */}
          <img className="my-img position-absolute top-0  w-100" src="images/blue.jpg" style={{ clipPath: `inset(0 ${100.2 - sliderPosition}% 0 0)` }} alt="" />
          {/* https://ik.imagekit.io/fnqqpf1l1/alpha/back-wo-remover.jpg?updatedAt=1733201378616 */}
          {/* foreground-image */}
          <img className=" foreground-img-bg  top-0  w-100" src="images/blue.png" style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }} alt="" />
          {/* https://ik.imagekit.io/fnqqpf1l1/alpha/back-w-remover.png?updatedAt=1733201507327 */}
          <input className=" slider input-range position-absolute" type="range" min={0} max={100} value={sliderPosition} onChange={handleSliderChange} />
        </div>

        <div className=" text-dark  upload">
          <h3 className="heading-3">
            Effortless
            <span
              style={{
                background: "linear-gradient(to right, #025dcc, darkblue)",
                WebkitBackgroundClip: "text",
                color: "transparent"
              }}
            >  Transparent<br />
             

            </span> Backgrounds. <br />
          </h3>
          <p className="para"> Got a challenging photo background?<br /> AlphaErase is your go-to solution<br className="responsive-br"/> for creating clean, distraction-free images.<br className="responsive-br" />
            With our advanced background remover,<br className="responsive-br"/> you can easily isolate your subject on a <br className="responsive-br" /> transparent backdrop, ready to be seamlessly <br className="responsive-br"/>added to any design. </p>
          <span style={{ color: "#1e7cee" }} className="fs-5">No Fees, No Limits, Just Results!</span>



        </div>
      </div>


      <div>

      </div>
    </div>
  )
}
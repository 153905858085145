import React from 'react';
import './processed-images.css';

export function ProcessedImage({ processedImage }) {
    if (!processedImage) return null;

    const handleDownloadPNG = () => {
        const a = document.createElement('a');
        a.href = processedImage;
        a.download = 'processed-image.png'; // Specify the default file name
        document.body.appendChild(a); // Append the element to the document
        a.click(); // Trigger the download
        document.body.removeChild(a); // Re
    };

    const handleDownloadJPG = async () => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Enable cross-origin for canvas
        img.src = processedImage;

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            canvas.width = img.width;
            canvas.height = img.height;

            // Fill the canvas with a white background
            context.fillStyle = "white";
            context.fillRect(0, 0, canvas.width, canvas.height);

            // Draw the image on top of the white background
            context.drawImage(img, 0, 0);

            // Convert to JPG format
            const jpgDataUrl = canvas.toDataURL('image/jpeg', 1.0);
            const a = document.createElement('a');
            a.href = jpgDataUrl;
            a.download = 'processed-image.jpg';
            a.click();
        };
    };

    return (
        <div className="removed-back-img d-flex">

            <div className=''>
                <img
                    src={processedImage}
                    alt="Processed"

                    className="img-fluid downloaded-img"
                />
            </div>

            <div>
                <div>
                    <button
                        className=" text-white fw-bold btn-png mt-4"
                        onClick={handleDownloadPNG}
                    >
                        &nbsp; <span className='bi bi-download'> </span>Download
                    </button><br />
                    <p className='fw-bold'>Download with Transparent Background (PNG format)</p>
                </div>
                <div>
                    <button
                        className="bi bi-download text-white fw-bold  btn-jpg mt-4"
                        onClick={handleDownloadJPG}
                    >
                        &nbsp; Download
                    </button><br />
                    <p className='fw-bold'>Download with White Background (JPG format)</p>
                </div>
            </div>

        </div>
    );
}

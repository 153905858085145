import './feature-collage.css';
export function FeatureCollage(){
    return(
        <div className='collage-section mt-0'>
            <div className="d-flex justify-content-center gap-2 flex-column flex-md-row feature-collage-container">
                <div className="left-collage">
                     <h3 className='heading-3'>A versatile background remover for all your needs!"</h3>
                     <p className='para'>Discover a versatile background remover designed for all your needs. Whether you're editing photos, product images, or creative visuals, our tool ensures quick and precise background removal.</p>
                </div>
                <div className="right-collage">
                    <div className='collage-container'>
                        <img className='collage-img' src="https://ik.imagekit.io/fnqqpf1l1/alpha/feature-collage.png?updatedAt=1733202169030" alt=""/>
                    </div>

                </div>
            </div>
        </div>
    )
}
import { useState } from 'react';
import './feature.css';
export function Feature() {
    const [sliderPosition, setSliderPosition] = useState(50);


    const handleSliderChange = (e) => {
        setSliderPosition(e.target.value);

    }
    return (
        <div className=' feature-section mt-5'>
            <h3 className='heading-3 text-center'>Create transparent backgrounds for any image </h3>
           <p className='para text-center'>Create transparent backgrounds for free</p>
            <div className="d-flex  justify-content-center gap-2 flex-column flex-md-row">

                <div className="left-side position-relative">
                    <div className="left-img-container">
                        {/* background image */}
                        <img className="left-img  position-absolute " src="https://ik.imagekit.io/fnqqpf1l1/alpha/women-w-back.jpg?updatedAt=1733201390396" style={{ clipPath: `inset(0 ${100.2 - sliderPosition}% 0 0)` }} alt="" />

                        {/* foreground image */}
                        <img className="left-img  position-absolute  foreground-img" src="https://ik.imagekit.io/fnqqpf1l1/alpha/women-wo-back.png?updatedAt=1733201500182"  style={{ clipPath: `inset(0 0 0 ${sliderPosition}%)` }} alt="" />

                        <input className="slider-feature input-range-feature position-absolute" type="range" min={0} max={100} value={sliderPosition} onChange={handleSliderChange} />
                    </div>
                </div>
                <div className="right-side position-relative ">
                    <h3 className='heading-3'>Background Transparency</h3>
                    <p className='para'>get started with our free background remover!</p>
                   <p className='para'>Outputs images with a transparent background (in PNG format), <br className='responsive-br'/> which is especially useful for e-commerce product images, avatars,<br className='responsive-br'/> or graphic design.</p>
                </div>
            </div>
        </div>
    )
}